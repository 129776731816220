import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    reply_to: '',
    mobile: '',
    title: '',
    message: '',
  });
  let timer;

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer,statusMessage]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAlert = () => {
    const alert = document.getElementById('alert');
    if (alert !== null) {
      alert.classList.remove('hidden');
      timer = setTimeout(() => {
        alert.classList.add('hidden');
      }, 10000);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!event.target.checkValidity()) {
      setStatusMessage(
        'Please ensure that all the required fields in the form are filled correctly to proceed.'
      );
      handleAlert();
      return;
    }
    formData.to_name = 'Nazeh';
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID_EMAIL_JS,
        process.env.REACT_APP_TEMPLATE_ID_EMAIL_JS,
        formData,
        process.env.REACT_APP_PUBLIC_KEY_EMAIL_JS
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setFormData({
            name: '',
            reply_to: '',
            mobile: '',
            title: '',
            message: '',
          });
          setStatusMessage('Great news! Your message has been sent successfully! Thank you for reaching out to us. We truly value your message and we promise to get back to you as soon as possible.');
          handleAlert();
        },
        (error) => {
          console.error('Email sending failed:', error);
          setStatusMessage('Sorry, an error occurred. Please try again later.');
          handleAlert();
        }
      );
  };
  return (
    <div className='px-0' id='contact'>
      <form className="md:w-3/4 mx-auto sm:w-2/3" onSubmit={handleSubmit} noValidate>
        <div className='md:mb-5'>
          {statusMessage && (
            <div id='alert'
              className={`${statusMessage.includes('successfully') ? 'bg-green-100 text-green-900' : 'bg-red-100 text-red-900'
                } px-4 py-3 mb-6 rounded-md`}
              role='alert'
            >
              {statusMessage}
            </div>
          )}
        </div>

        <div className="flex flex-wrap -mx-3 mb-6  mt-5">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block">
              <span className="text-gray-700 text-sm font-medium">Full Name</span>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="John Doe"
                required
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block">
              <span className="text-gray-700 text-sm font-medium">Email Address</span>
              <input
                type="email"
                name="reply_to"
                value={formData.reply_to}
                onChange={handleChange}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="you@example.com"
                required
              />
            </label>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label className="block">
              <span className="text-gray-700 text-sm font-medium">Contact Number</span>
              <input
                type="tel"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="(+123) 234XXXXXX"
                required
              />
            </label>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <label className="block">
              <span className="text-gray-700 text-sm font-medium">Title of Inquiry</span>
              <input
                type="text"
                name='title'
                value={formData.title}
                onChange={handleChange}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
                placeholder="Inquiry title"
                required
              />
            </label>
          </div>
        </div>
        <div className="w-full sm:w-auto mt-5 mb-6 md:mb-0 flex-1">
          <label className="block">
            <span className="text-gray-700 text-sm font-medium">Message</span>
            <textarea
              rows={10}
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1"
              placeholder="Enter your message here"
              required
            />
          </label>
        </div>

        <div className="w-full sm:w-auto mt-5 mb-6 md:mb-0 flex-none">
          <button
            type="submit"
            className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-900 text-white hover:bg-slate-700"
          >
            Submit Inquiry
          </button>
        </div>
      </form>

    </div>
  );
};

export default ContactForm;
