import { useState } from "react";
import OpenLabLogo from './assets/images/logo-blue.png';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="relative z-50 w-full flex-none text-sm px-10 font-semibold leading-6 text-slate-900">
      <nav className="flex items-center justify-between flex-wrap p-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-72">
          <a className="mr-auto flex-none text-slate-900" href="/">
            <span className="sr-only">Open Lab Technologies</span>
            <div className="text-left">
              <img
                src={OpenLabLogo}
                alt="Open Lab Technologies"
                className="h-12 w-auto lg:h-14 lg:w-100"
              />
            </div>
          </a>
        </div>

        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="-my-1 -mr-1 ml-6 flex h-8 w-8 items-center justify-center lg:hidden"
          >
            <span className="sr-only">Open navigation</span>
            <svg
              viewBox="0 0 24 24"
              className={`h-6 w-6 stroke-slate-900 ${isOpen ? "hidden" : "block"
                }`}
            >
              <path
                d="M3.75 12h16.5M3.75 6.75h16.5M3.75 17.25h16.5"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
              ></path>
            </svg>
            <svg
              className={`h-6 w-6 stroke-slate-900 ${isOpen ? "block" : "hidden"
                }`}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div
          className={`lg:flex lg:items-center md:flex ${isOpen ? "block" : "hidden"
            }`}
        >
          <a
            className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4"
            href="#services"
          >
            Services
          </a>
          <div>
            <div className="mt-5 lg:mt-0 mx-0 lg:ml-8 lg:flex lg:items-center lg:border-l lg:border-slate-900/15 lg:pl-8">
              <a
                className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-900 text-white hover:bg-slate-700 -my-2.5 lg:ml-8 sm:ml-0"
                href="#contact"
              >
                <span>
                  Get In Touch <span aria-hidden="true">→</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
