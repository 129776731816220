
import './App.css';
import Typed from "react-typed"
import Header from './Header';
import Footer from './Footer';
import ContactForm from './ContactForm';
import PageHero from './assets/images/page-hero-bulb.jpg'
function App() {
  return (
    <div className="font-sans antialiased text-gray-600 lg:min-h-full sm:h-1/2 flex flex-col [overflow-anchor:none]">
      <Header />
      <div className="relative h-screen overflow-hidden">
        <img src={PageHero} alt="Hero page" className="backdrop-opacity-80 absolute object-cover w-full h-full" />

        <div className="absolute inset-0 bg-gray-900 opacity-75"></div>

        <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
          <p className="mt-4 text-4xl font-extrabold tracking-[-0.04em] lg:leading-tight text-center sm:text-8xl sm:leading-[3.5rem]">Your door to the future with Open Lab Technologies</p>
          <p className="text-1xl lg:text-2xl font-extrabold text-center text-white mt-5">  <Typed
            strings={[
              "where innovative ideas take shape.",
              "where ideas come to life.",
              "where ideas take center stage",
              "Join us and turn your ideas into reality",
            ]}
            typeSpeed={50}
            backSpeed={30}
            loop
          /></p>
          <div className="mt-8 mx-auto flex justify-center lg:w-2/5">
            <a className="rounded-lg text-sm font-semibold py-3 px-4 text-white border-white border hover:bg-slate-700 hover:text-white" href="#contact">
              <span>Get In Touch <span aria-hidden="true" className="text-slate-400 sm:inline">→</span></span>
            </a>
          </div>
        </div>
      </div>


      {/* <div className="mx-auto max-w-container px-4 pt-4 sm:px-6 lg:flex lg:px-8">
        <div className="relative z-20 w-full mx-auto max-w-[65rem] pb-16 pt-16 lg:mx-0 lg:w-[65rem] lg:max-w-none lg:flex-none lg:pb-24 lg:pr-4 lg:pt-20">
          <p className="mt-4 text-3xl font-extrabold tracking-[-0.04em] text-center lg:text-black sm:text-5xl sm:leading-[3.5rem]">Your door to the future with Open Lab Technologies</p>
          <p className='text-center text-2xl  font-extrabold text-slate-600'>where ideas come to life.</p>

          <p className="mt-4 text-base leading-7 text-slate-600">Welcome to Open Lab Technologies - the center of innovation. Our lab is always open for collaboration, experimentation, and pushing boundaries. Join our community of innovators to turn dreams into reality with open-source innovation. Let's shape the world, one idea at a time.</p>

          <div className="mt-8 mx-auto flex justify-center lg:w-2/5">
            <a className="rounded-lg text-sm font-semibold py-3 px-4 bg-slate-900 text-white hover:bg-slate-700" href="/contact">
              <span>Get In Touch <span aria-hidden="true" className="text-slate-400 sm:inline">→</span></span>
            </a>
          </div>

        </div>
      </div> */}


      <div className="relative  sm:z-auto mt-10" id='services'>
        <div className="mx-auto max-w-container px-4 pb-16 sm:px-6 lg:px-8">
          <div className="relative mx-auto grid max-w-[40rem] grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3">
            <div className="flex">
              <div className="p-0.5">
                <svg className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]" viewBox="0 0 40 40" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z" className="fill-indigo-50 stroke-indigo-500"></path>
                  <path d="M27 25.335L30.25 27l-6.603 3.382a8 8 0 01-7.294 0L9.75 27 13 25.335m14-6.999L30.25 20l-6.603 3.382a8 8 0 01-7.294 0L9.75 20 13 18.336" className="stroke-indigo-500/50"></path>
                  <path d="M9.75 13l9.338-4.783a2 2 0 011.824 0L30.25 13l-6.603 3.382a8 8 0 01-7.294 0L9.75 13z" className="stroke-indigo-500"></path>
                </svg>
              </div>
              <div className="ml-6">
                <h2 className="text-lg font-semibold leading-6 text-slate-900">Software Development Services</h2>
                <p className="mt-2 text-sm leading-6 text-slate-700">We specialize in building high-quality software that empowers businesses to achieve their goals. Our team of experienced developers works closely with you to deliver tailored solutions that meet your unique business needs. Whether you need new software built, existing systems integrated, or ongoing maintenance and support, we have the skills and expertise to deliver efficient and effective results.</p>
                <p className="mt-2 text-sm leading-6 text-slate-700">Our collaborative approach to development ensures that our solutions align with your vision and goals. Contact us today to learn more about how our expert software development services can transform your business.</p>
              </div>
            </div>
            <div className="flex">
              <div className="p-0.5">
                <svg className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]" viewBox="0 0 40 40" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z" className="fill-indigo-50 stroke-indigo-500"></path>
                  <path d="M22.25 11.75l-4.5 16.5" className="stroke-indigo-500/50"></path>
                  <path d="M16.25 7.75h-.5a4 4 0 00-4 4v4.007a3 3 0 01-.88 2.122 3 3 0 000 4.242 3 3 0 01.88 2.122v4.007a4 4 0 004 4h.5m7.5-24.5h.5a4 4 0 014 4v4.007a3.002 3.002 0 00.878 2.122 3 3 0 010 4.242 3 3 0 00-.878 2.122v4.007a4 4 0 01-4 4h-.5" className="stroke-indigo-500"></path>
                </svg>
              </div>
              <div className="ml-6">
                <h2 className="text-lg font-semibold leading-6 text-slate-900">Mobile App Development</h2>
                <p className="mt-2 text-sm leading-6 text-slate-700">We develop custom iOS and Android apps tailored to your business needs, using cutting-edge technologies for optimal user experience.</p>
                <p className="mt-2 text-sm leading-6 text-slate-700">Our streamlined process ensures on-time delivery and budget adherence, with ongoing maintenance and support for optimal performance.</p>
                <p className="mt-2 text-sm leading-6 text-slate-700">Engage with your customers and stay ahead of the competition. Contact us today to develop your mobile app.</p>
              </div>
            </div>
            <div className="flex">
              <div className="p-0.5">
                <svg className="h-10 w-10 shrink-0 transform-cpu rounded-lg shadow-md shadow-indigo-500/[.12]" viewBox="0 0 40 40" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M.75 8A7.25 7.25 0 018 .75h24A7.25 7.25 0 0139.25 8v24A7.25 7.25 0 0132 39.25H8A7.25 7.25 0 01.75 32V8z" className="fill-indigo-50 stroke-indigo-500"></path>
                  <path d="M7.75 21.25v-1.5a4 4 0 014-4h7.5m5 5v7.5a4 4 0 01-4 4h-1.5M27.75 32.25h.5a4 4 0 004-4v-10.5m-10-10h-10.5a4 4 0 00-4 4v.5" className="stroke-indigo-500/50"></path>
                  <path d="M31.5 8.5l-23 23" className="stroke-indigo-500"></path>
                  <path d="M25.75 7.75h4.5a2 2 0 012 2v4.5m-18 18h-4.5a2 2 0 01-2-2v-4.5" className="stroke-indigo-500"></path>
                </svg>
              </div>
              <div className="ml-6">
                <h2 className="text-lg font-semibold leading-6 text-slate-900">Machine Learning and AI Solutions</h2>
                <p className="mt-2 text-sm leading-6 text-slate-700">Stay ahead of the competition with our customized machine learning and AI solutions. We specialize in predictive analytics, natural language processing, and computer vision solutions to automate processes, improve efficiency, and make data-driven decisions. Our experts develop cutting-edge solutions that are fully responsive and carefully designed to look great on any screen size.</p>
              </div>
            </div>

            <div className="flex">
              <div className="p-0.5">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 rotate-45" viewBox="0 0 40 40" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="20" cy="20" r="18" stroke="indigo" fill="none"></circle>
                  <line x1="12" y1="12" x2="28" y2="28"></line>
                  <line x1="12" y1="28" x2="28" y2="12"></line>
                </svg>

              </div>
              <div className="ml-6">
                <h2 className="text-lg font-semibold leading-6 text-slate-900">Consultancy Services</h2>

                <p className="mt-2 text-sm leading-6 text-slate-700">
                  Elevate your business with our comprehensive consultancy services. Open Lab Technologies is dedicated to advancing your company's digital presence, focusing on refined software design and development, as well as leveraging cutting-edge AI and machine learning solutions.
                </p>
                <p className="mt-2 text-sm leading-6 text-slate-700">
                  We provide expert guidance to both established businesses and budding entrepreneurs, equipping them with the tools to outpace the competition and achieve market leadership. Our team is adept at delivering strategic insights and conducting in-depth competitor analysis.
                </p>
                <p className="mt-2 text-sm leading-6 text-slate-700">
                  We design bespoke solutions tailored to maintain your leading edge in technological innovation. Partner with Open Lab Technologies to transform your operations and unleash your full potential in the digital era.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>

      {/* <div className="px-10 pt-1  bg-slate-900/75" id='portfolio'>
        <h1 className="text-3xl font-bold mb-8 mt-10 text-slate-900">Our Portfolio</h1>
        <div className="flex flex-wrap -mx-4">
          <a className="group w-full md:w-1/3 px-4 mb-8" href="/showcase/openai">
            <div className="bg-slate-800/80 rounded-lg overflow-hidden">
              <img src={RightBox} alt="Portfolio Item 2" className="max-h-45 w-full object-contain" />
              <div className="flex flex-wrap items-center mt-3 px-4 py-2">
                <h2 className="text-lg leading-6 text-black dark:text-white font-semibold group-hover:text-sky-500 dark:group-hover:text-sky-400">
                  <a href="/showcase/openai">
                    <span className="absolute inset-0 rounded-3xl"></span>
                    Rightbox Inc UK
                  </a>
                </h2>
                <svg className="w-6 h-6 flex-none opacity-0 group-hover:opacity-100" viewBox="0 0 24 24" fill="none">
                  <path d="M9.75 15.25L15.25 9.75M15.25 9.75H10.85M15.25 9.75V14.15" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <p className="w-full flex-none text-[0.8125rem] leading-6 text-slate-500 dark:text-slate-400 group-hover:text-sky-500 dark:group-hover:text-sky-400">
                RightBox simplifies buying cardboard boxes for businesses by offering an online platform to source and compare offers from reputable suppliers, free of charge. Their industry experience empowers buyers to thrive with packaging that meets their needs.
                </p>
              </div>
            </div>
          </a>
          <a className="group w-full md:w-1/3 px-4 mb-8" href="/showcase/openai">
            <div className="bg-slate-800/80 rounded-lg overflow-hidden">
              <img src={GardenstateMedicalGroup} alt="Portfolio Item 2" className="max-h-50 w-full object-contain" />
              <div className="flex flex-wrap items-center mt-3 px-4 py-2">
                <h2 className="text-lg leading-6 text-black dark:text-white font-semibold group-hover:text-sky-500 dark:group-hover:text-sky-400">
                 
                    <span className="absolute inset-0 rounded-3xl"></span>
                    Medical Garden state

                </h2>
                <svg className="w-6 h-6 flex-none opacity-0 group-hover:opacity-100" viewBox="0 0 24 24" fill="none">
                  <path d="M9.75 15.25L15.25 9.75M15.25 9.75H10.85M15.25 9.75V14.15" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <p className="w-full flex-none text-[0.8125rem] leading-6 text-slate-500 dark:text-slate-400 group-hover:text-sky-500 dark:group-hover:text-sky-400">
                  Garden State Medical, located in New Jersey, provides quality medical services including primary care, urgent care, laboratory and imaging services, and specialized care. Their experienced healthcare providers offer compassionate care to patients.
                </p>
              </div>
            </div>
          </a>

          <a className="group w-full md:w-1/3 px-4 mb-8" href="/showcase/openai">
            <div className="bg-slate-800/80 rounded-lg overflow-hidden">
              <img src={GardenstateMedicalGroup} alt="Portfolio Item 2" className="w-full" />
              <div className="flex flex-wrap items-center mt-3 px-4 py-2">
                <h2 className="text-lg leading-6 text-black dark:text-white font-semibold group-hover:text-sky-500 dark:group-hover:text-sky-400">
                  <a href="/showcase/openai">
                    <span className="absolute inset-0 rounded-3xl"></span>
                    AySquared Market Place
                  </a>
                </h2>
                <svg className="w-6 h-6 flex-none opacity-0 group-hover:opacity-100" viewBox="0 0 24 24" fill="none">
                  <path d="M9.75 15.25L15.25 9.75M15.25 9.75H10.85M15.25 9.75V14.15" stroke="#0EA5E9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <p className="w-full flex-none text-[0.8125rem] leading-6 text-slate-500 dark:text-slate-400 group-hover:text-sky-500 dark:group-hover:text-sky-400">
                This ecommerce app offers a hassle-free grocery shopping experience in the US with competitively priced products, secure payment options, and convenient delivery, including same-day or next-day delivery in select areas.
                </p>
              </div>
            </div>
          </a>
        </div>
      </div> */}

      {/* <div className="mx-3 sm:px-6 lg:px-8 py-8" id="partners">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold mb-8">Our Partners</h1>

        <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 gap-5">
          <div className="flex justify-center items-center">
            <img src="https://dummyimage.com/300x300/cccccc/ffffff" alt="Partner Logo" className="max-h-28 sm:max-h-32 md:max-h-40 lg:max-h-48" />
          </div>
          <div className="flex justify-center items-center">
            <img src="https://dummyimage.com/300x300/cccccc/ffffff" alt="Partner Logo" className="max-h-28 sm:max-h-32 md:max-h-40 lg:max-h-48" />
          </div>
          <div className="flex justify-center items-center">
            <img src="https://dummyimage.com/300x300/cccccc/ffffff" alt="Partner Logo" className="max-h-28 sm:max-h-32 md:max-h-40 lg:max-h-48" />
          </div>
          <div className="flex justify-center items-center">
            <img src="https://dummyimage.com/300x300/cccccc/ffffff" alt="Partner Logo" className="max-h-28 sm:max-h-32 md:max-h-40 lg:max-h-48" />
          </div>
          <div className="flex justify-center items-center">
            <img src="https://dummyimage.com/300x300/cccccc/ffffff" alt="Partner Logo" className="max-h-28 sm:max-h-32 md:max-h-40 lg:max-h-48" />
          </div>
          <div className="flex justify-center items-center">
            <img src="https://dummyimage.com/300x300/cccccc/ffffff" alt="Partner Logo" className="max-h-28 sm:max-h-32 md:max-h-40 lg:max-h-48" />
          </div>
        </div>
      </div> */}
      <div className="flex flex-wrap mb-6 px-20  mt-5">
        <div className="md:w-3/4 mx-auto sm:w-2/3">
          <h1 className='text-4xl mx-auto text-center mb-5'>Get in touch with us! </h1>
          <p className='text-gray-700 text-2xl md:w-2/3 mx-auto mb-10 font-medium text-center'>Fill out the form below and we'll get back to you as soon as possible.</p>
        </div>

        <div className="w-full md:w-2/5 px-0 md:px-10 mb-6 md:mb-0 pt-10">
          <div className="bg-white rounded-lg">
            <div className="mb-4">
              <h3 className="text-xl font-bold">Address (UK)</h3>
              <p className="text-gray-700">
                61, Allen Road<br />
                Wolverhampton, WV6 0AW, UK
              </p>
              <p className="text-gray-700 mt-2">
                Mobile Number: (+44) 074 402 34545</p>
            </div>
            <div className="mt-4">
              <h3 className="text-xl font-bold">Address (Nigeria)</h3>
              <p className="text-gray-700">
                Flat 1M Behind Kadwell International School<br />
                Barnawa, Kaduna State, Nigeria
              </p>
              <p className="text-gray-700 mt-2">
                Mobile Number: (+234) 081 650 89768
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/5 px-3 ">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App;
